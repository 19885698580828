<template>
  <div class="page">
    <nav-top :index="3" ref="navTop" ></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="/">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item">
            <router-link to="/report">快速报备</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="#">楼盘详情</router-link>
          </div>
        </div>
        <div class="search">
          <div class="search-text">
            <div class="text">
              <input type="text" placeholder="请输入关键词">
            </div>
            <div class="btn">
              <img src="/static/images/fdj_ico2.png" alt="">
            </div>
          </div>
          <router-link class="report-btn" to="/report">
            <span>快速报备</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="houses-info">
      <div class="houses-title">
        <div class="title">
          <span>楼盘信息</span>
          <span>/ COLUMN NAVIGATION</span>
        </div>
      </div>
      <div class="houses-tab">
        <router-link :to="`/HousesHome?id=${id}`" class="tab" :class="{active:tagName === 'HousesHome'}">
          <span>楼盘首页</span>
        </router-link>
        <router-link :to="`/HousesInfo?id=${id}`" class="tab" :class="{active:tagName === 'HousesInfo'}">
          <span>楼盘详情</span>
        </router-link>
        <router-link :to="`/HousesShow?id=${id}`" class="tab" :class="{active:tagName === 'HousesShow' ||tagName === 'HousesShowInfo'}">
          <span>户型展示</span>
        </router-link>
        <router-link :to="`/HousesSurrounding?id=${id}`" class="tab" :class="{active:tagName === 'HousesSurrounding'}">
          <span>周边配套</span>
        </router-link>
        <router-link :to="`/HousesAlbum?id=${id}`" class="tab" :class="{active:tagName === 'HousesAlbum'}">
          <span>楼盘相册</span>
        </router-link>
        <router-link :to="`/HousesDynamic?id=${id}`" class="tab" :class="{active:tagName === 'HousesDynamic'}">
          <span>楼盘动态</span>
        </router-link>
      </div>
      <div class="pit-info">
        <router-view></router-view>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'HousesDetails',
  components: {FooterBar, NavTop},
  data(){
    return{
      id:0,
      tagName:''
    }
  },
  provide(){
    return{
      login:this.login
    }
  },
  mounted() {
    this.tagName = this.$route.name
    this.id = this.$route.query.id

  },methods:{
    login(){
      this.$refs['navTop'].login()
    }
  },
  watch:{
    '$route':function (val){
      this.tagName = val.name
    }
  }
}
</script>

<style scoped lang="scss">
@import "HousesDetails.scss";
</style>
